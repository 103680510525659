import CelCentral from "../assets/images/CelCentral.svg";
import CelDeixaComigo from "../assets/images/CelDeixacomigo.svg";
import DeskCentral from "../assets/images/DeskCentral.svg";
import DeskDeixaComigo from "../assets/images/DeskDeixacomigo.svg";
import DeskCita from "../assets/images/cita-desk.svg";
import CelCita from "../assets/images/cita-mob.svg";
import DeskMR from "../assets/images/mr-desk.svg";
import CelMR from "../assets/images/mr-mob.svg";
import DeskRede from "../assets/images/rede-desk.svg";
import CelRede from "../assets/images/rede-mob.svg";

export const ArrayImages = [
  { desk: DeskDeixaComigo, mobile: CelDeixaComigo, color: "#48D904" },
  { desk: DeskCentral, mobile: CelCentral, color: "#001E62" },
  { desk: DeskCita, mobile: CelCita, color: "#96A8CE" },
  { desk: DeskMR, mobile: CelMR, color: "#252323" },
  { desk: DeskRede, mobile: CelRede, color: "#FFFFFF" },
];
