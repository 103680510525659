import React from 'react';
import LazyLoad from 'react-lazyload';

import "./styles.css";

function TitlePage(props){
  if(props.lazyLoad){
    return(
      <LazyLoad height={50} offset = {-100} >
        <div className="content-title-page " data-margin={props.dataMargin}>
          <div className="line animate__animated animate__slideInLeft" ></div>
          <div className="titles">
            <h3 className="animate__animated animate__slideInLeft" >{props.title}</h3>
            { props.titleBold ? <h3 className="h3Bold animate__animated animate__slideInRight">{props.titleBold}</h3> :
              <h5 className="paragrafo animate__animated animate__slideInRight">{props.paragrafo}</h5> 
            }
          </div>
          <div className="line animate__animated animate__slideInRight" ></div>
        </div>
      </LazyLoad>
    )
  }
  return (
    <div className="content-title-page " data-margin={props.dataMargin}>
      <div className="line animate__animated animate__slideInLeft" ></div>
      <div className="titles">
        <h3 className="animate__animated animate__slideInLeft" >{props.title}</h3>
        { props.titleBold ? <h3 className="h3Bold animate__animated animate__slideInRight">{props.titleBold}</h3> :
          <h5 className="paragrafo animate__animated animate__slideInRight">{props.paragrafo}</h5> 
        }
      </div>
      <div className="line animate__animated animate__slideInRight" ></div>
    </div>
  )
}

export default TitlePage;