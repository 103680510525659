import React from "react";

import "./styles.css";

function DivImagensPortifolio(props) {
  return (
    <div className="content-images-port">
      <img className="img-desk" src={props.desk} alt="imagem desktop" />
      <img className="img-mobile" src={props.mobile} alt="imagem mobile" />
    </div>
  );
}

export default DivImagensPortifolio;
