import React from "react";
import ListWorks from "../ListWorks";

import "./styles.css";

function CardMetodologia(props) {
  return (
    <div className="content-metodologia">
      <div className="div-text-metodologia">
        <h4 className="title-metodologia">{props.subTitle}</h4>
        <p className="text-metodologia">{props.children}</p>
        <ListWorks topics={props.list} />
      </div>
      <img
        className="image-metodologia"
        data-image={props.dataMargin}
        src={props.image}
        alt="metodologia um"
      />
    </div>
  );
}

export default CardMetodologia;
