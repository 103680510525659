import React from 'react';
import MensagemSVG from '../../assets/images/mensagem.svg';
import './styles.css';

function ButtonContato(props){

  const handleClick = () => {
    if(props.onClick){
      props.onClick()
    }
  }

  return (
    <button className="content-button-contato" type={props.type} onClick={handleClick}>
      { props.icon && <img src={MensagemSVG} alt="imagem contato"></img>}
      <p>{props.children}</p>
    </button>
  )
}

export default ButtonContato;