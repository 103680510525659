import React from 'react';

import './styles.css';

function DivTecnologia(props){

  const handleClick = (index) =>  {
    props.onClick(index)
  };

  return(
    <div className="div-steps-tecnologia">
      <div className="row-tecnologia">
        {
          props.tecnologias.map(icons => {
            return (
              <>
                <div className="circle-row">{icons}</div>   
              </>
            )
          })
        }
      </div>
      <div className="content-steps-tecnologia">
      {
          props.arrayIcon.map((icons, index) => {
            return <div 
              key={index} 
              className="steps-tecnologia" 
              data-select={props.step === index ? 'select': ''} 
              onClick={() => handleClick(index)}
            />
          })
      }
      </div>
    </div>
  )
}

export default DivTecnologia;