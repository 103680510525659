import React from 'react';
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";

import SendEmail from '../../assets/lordIcon/177-envelope-mail-send-outline.json';

import './styles.css';

function CardStepOrcamento(props){

  defineLordIconElement(loadAnimation);

  return(
    <div className="content-step-orcamento">
      {/* <img src={props.image} /> */}
      <lord-icon
        src={props.lorIcon}
        trigger="loop-on-hover"
        colors="primary:#FF7400,secondary:#000000"
        stroke="46"
        style={{width:"150px",height:"150px"}}>
      </lord-icon>
      <h5 className="title-step">{props.title}</h5>
      <p className="paragrafo-step">{props.children}</p>
    </div>
  )
}

export default CardStepOrcamento;