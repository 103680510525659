import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
// import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import AlertPage from "../AlertPage";
import TitlePage from "../TitlePage";
import CardTypeWork from "./CardTypeWork";

import botãoFechar from "../../assets/images/botãoFechar.svg";
import ButtonContato from "../ButtonContato";
import ItemStep from "../ItemStep";
import "./styles.css";

const OrangeRadio = withStyles({
  root: {
    color: "orange",
    "&$checked": {
      color: "orange",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function DialogContato(props) {
  const [selectedValue, setSelectedValue] = useState(true);
  const [firstClass, setFirstClass] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [classAnimate, setClassAnimate] = useState("animate__fadeOutDown");
  const [nome, setNome] = useState("");
  const [empresa, setEmpresa] = useState("");
  // const [email, setEmail] = useState('');
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [whatsApp, setWhatsapp] = useState("");
  const [interesseTrabalhos, setInteresseTrabalhos] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [openAlert, setOpenAlert] = useState("");
  const [textAlert, setTextAlert] = useState("");
  const [colorAlert, setColorAlert] = useState("success");

  let worksSelect = [];

  const handleClose = () => {
    props.onClose();
  };

  const handleClickRadio = () => {
    setSelectedValue(!selectedValue);
  };

  const nextStep = (event, id) => {
    event.preventDefault();

    if (id) {
      return setStepNumber(id);
    }

    if (stepNumber === 1) {
      setStepNumber(2);
    } else if (stepNumber === 2) {
      setStepNumber(3);
    }
  };

  const backToStep = () => {
    return setStepNumber(stepNumber - 1);
  };

  const colorStep = () => {
    if (stepNumber === 1) {
      return "primary";
    } else if (stepNumber === 2) {
      setStepNumber(3);
    } else {
    }
  };

  const handleClickTypeWork = (evento, typeWork) => {
    if (evento === "add") {
      worksSelect.push(typeWork);
    } else {
      let newWorkSelect = [];
      worksSelect.forEach((work) => {
        if (work === typeWork) {
          return;
        } else {
          newWorkSelect.push(work);
        }
      });
      worksSelect = newWorkSelect;
    }

    setInteresseTrabalhos(worksSelect);
  };

  const handleSend = async (event) => {
    event.preventDefault();

    const mensagemPreDefinida = `Olá, estou interessado(a) no serviço de ${interesseTrabalhos}. Gostaria de obter mais informações.\n\nNome: ${nome}\nEmpresa: ${empresa}\nCelular: ${celular}\nDescrição: ${descricao}`;

    const numeroWhatsApp = "5511977189773";

    const urlWhatsApp = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${encodeURIComponent(
      mensagemPreDefinida
    )}`;

    window.open(urlWhatsApp, "_blank");
    // emailjs
    //   .send(
    //     "service_i5zg3gd",
    //     "template_lezkazk",
    //     {
    //       nome: nome,
    //       empresa: empresa,
    //       email: email,
    //       celular: celular,
    //       telefone: telefone,
    //       tipo: interesseTrabalhos,
    //       mensagem: descricao,
    //       isCtWhatsApp: whatsApp,
    //     },
    //     "user_8GRTW6N4guKsUmBNlzsRR"
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     if (res.status === 200) {
    //       setOpenAlert("show");
    //       setColorAlert("success");
    //       setTextAlert("SOLICITAÇÃO ENVIADA, RETORNAREMOS EM BREVE!");
    //     }
    //   })
    //   .catch((error) => {
    //     setOpenAlert("show");
    //     setColorAlert("error");
    //     setTextAlert("Erro no envio tente novamente mais tarde!");
    //   });
  };

  useEffect(() => {
    if (props.open) {
      setClassAnimate("animate__fadeInUp");
      setFirstClass(true);
    } else {
      setClassAnimate("animate__fadeOutDown");
    }
  }, [props.open]);

  useEffect(() => {
    if (selectedValue) {
      setWhatsapp("Sim");
    } else {
      setWhatsapp("Não");
    }
  }, [selectedValue]);

  return (
    <div
      className={`animate__animated ${classAnimate} ${
        firstClass ? "cardOpen" : "cardOff"
      }`}
      aria-labelledby="form-dialog-title"
    >
      <AlertPage display={openAlert} color={colorAlert}>
        {textAlert}
      </AlertPage>
      <button className="button-close" onClick={handleClose}>
        <img src={botãoFechar} alt="fechar" />
      </button>
      <TitlePage
        dataMargin="25"
        title="FAÇA CONTATO"
        paragrafo="Primeiro, preencha com as suas informações de contato"
      />
      <div className="div-content-form">
        <div className="content-steps-contato">
          <ItemStep
            number="1"
            onClick={nextStep}
            dataColor={
              (stepNumber === 1 || stepNumber === 2 || stepNumber === 3) &&
              "primary"
            }
          />
          <div
            className="line-step"
            data-display={(stepNumber === 2 || stepNumber === 3) && "show"}
          />
          <ItemStep
            number="2"
            onClick={nextStep}
            dataColor={
              stepNumber === 2 || stepNumber === 3 ? "primary" : "primary--1"
            }
          />
          <div
            className="line-step"
            data-display={stepNumber === 3 && "show"}
          />
          <ItemStep
            number="3"
            onClick={nextStep}
            dataColor={stepNumber === 3 ? "primary" : "primary--1"}
          />
        </div>
        {stepNumber === 1 && (
          <form
            className="form-contato animate__animated animate__fadeInRight"
            onSubmit={nextStep}
          >
            <div>
              <TextField
                required
                autoFocus={true}
                fullWidth={true}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
              />
            </div>
            {/* <TextField 
                required
                className="textField-email" 
                id="outlined-basic" 
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              /> */}
            <div>
              <TextField
                fullWidth={true}
                required
                id="outlined-basic"
                label="Celular"
                variant="outlined"
                value={celular}
                onChange={(event) => {
                  var x = event.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
                  setCelular(
                    (event.target.value = !x[2]
                      ? x[1]
                      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""))
                  );
                }}
              />
              <TextField
                required
                fullWidth={true}
                id="outlined-basic"
                label="Empresa"
                variant="outlined"
                value={empresa}
                onChange={(event) => setEmpresa(event.target.value)}
              />
              {/* <TextField
                fullWidth={true}
                required
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                value={telefone}
                onChange={(event) => {
                  var x = event.target.value
                    .replace(/\D/g, "")
                    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
                  setTelefone(
                    (event.target.value = !x[2]
                      ? x[1]
                      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : ""))
                  );
                }}
              /> */}
            </div>
            {/* <FormControlLabel
              value="wpp"
              control={
                <OrangeRadio
                  checked={selectedValue}
                  onClick={handleClickRadio}
                />
              }
              label="Prefiro ser contatado pelo WhatsApp"
            /> */}
            <div className="actions">
              <ButtonContato type="submit">PROXIMO PASSO</ButtonContato>
            </div>
          </form>
        )}
        {stepNumber === 2 && (
          <form
            className="content-step-two animate__animated animate__fadeInRight"
            onSubmit={nextStep}
          >
            <div className="row-card-works">
              <CardTypeWork
                title="MARKETING DIGITAL"
                text="Estratégias eficazes para alcançar e satisfazer seu público-alvo, gerando lucro."
                onClick={handleClickTypeWork}
              />
              <CardTypeWork
                title="GERENCIAMENTO DE REDES SOCIAIS"
                text="Criação, programação e otimização de conteúdo para suas redes sociais."
                onClick={handleClickTypeWork}
              />
            </div>
            <div className="row-card-works">
              <CardTypeWork
                title="DESENVOLVIMENTO DE SITE"
                text="Criação de sites responsivos e atraentes, adequados para qualquer finalidade (e-commerce, landing pages, etc.)."
                onClick={handleClickTypeWork}
              />
              <CardTypeWork
                title="DESENVOLVIMENTO DE SISTEMA"
                text="Desenvolvimento de sistemas sob medida para atender às necessidades específicas da sua empresa."
                onClick={handleClickTypeWork}
              />
            </div>
            <div className="actions">
              <button className="button-voltar" onClick={backToStep}>
                VOLTAR
              </button>
              <ButtonContato type="submit">PROXIMO PASSO</ButtonContato>
            </div>
          </form>
        )}
        {stepNumber === 3 && (
          <form
            className="content-text-area animate__animated animate__fadeInRight"
            onSubmit={handleSend}
          >
            <textarea
              className="text-area"
              placeholder="Fique á vontade para nos contar detalhes do projeto, urgência e até mesmo o investimento disponível..."
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
            ></textarea>
            <div className="actions">
              <button className="button-voltar" onClick={backToStep}>
                VOLTAR
              </button>
              <ButtonContato type="submit">ENVIAR</ButtonContato>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
