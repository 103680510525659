import React from 'react';

import './styles.css';

function ItemStep(props){
  
  const handleClick = () => {
    props.onClick(parseInt(props.number));
  }

  return (
    <div className={`content-step`} data-color={props.dataColor} onClick={handleClick}>
      <p>{props.number}</p>
    </div>
  )
}

export default ItemStep;