import React from 'react';

import './styles.css';

function ButtonNavBar(props){
  return (
    <a className="content-button-navbar" href={props.href}>
      <p>{props.children}</p>
    </a>
  )
}

export default ButtonNavBar;