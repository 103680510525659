import React, { useEffect, useState } from 'react';

import "./styles.css";

function Menu(props){

  return (
    <div className={`animate__animated ${props.classAnimate} content-menu`} >
      {props.children}
    </div>
  )
}

export default Menu;