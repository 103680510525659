export const worksOne = {
  "APP": {
    title: "DESENVOLVIMENTO DE APLICATIVO",
    subTitle: "Do seu jeito, para todas plataformas e dispositivos.",
    text: "Diga o que deseja e nós entregamos para o seu negócio um Aplicativo totalmente moderno, focado na experiência do usuário, levando maior confiabilidade para seus clientes.",
    list: ["Design responsivo", "Layout moderno", "API’s de integração", "App com alto desempenho"]
  },
  "WEB": {
    title: "DESENVOLVIMENTO DE WEBSITE",
    subTitle: "Seu site como quiser, para o que desejar. Mais fácil do que nunca.",
    text: "Diga o que deseja e nós entregamos para o seu negócio um Website totalmente moderno, focado na experiência do usuário, levando maior confiabilidade para seus clientes.",
    list: ["Design responsivo", "Layout moderno", "API’s de integração", "Site com alto desempenho"]
  },
  "DESIGN UI/UX": {
    title: "DESENVOLVIMENTO DE DESIGN UI/UX",
    subTitle: "Construimos seu projeto do “zero”, desde o desenho até a aplicação.",
    text: "Diga o que deseja e nós entregamos seu projeto com um design atual, trazendo maior confiabilidade ao seu negócio e com nossos especialistas, a experiência e interface de usuário serão das melhores.",
    list: ["Design moderno", "Plataforma interativa e intuitiva", "API’s de integração"]
  },
  "SISTEMAS": {
    title: "DESENVOLVIMENTO DE SISTEMAS",
    subTitle: "Você ou sua empresa precisa de um sistema específico?",
    text: "Sua empresa ou até mesmo você tem uma necessidade de solução e precisa que seja implementada através de um software ou sistema web? Iremos planejar e desenvolver exatamente como você precisa.",
    list: ["Solução personalizada", "Fácil instalação e manutenção", "Projeto escalável e robusto"],
  },
  "MARKETING DIGITAL": {
    title: "DESENVOLVIMENTO DO MARKETING DIGITAL",
    subTitle: "Acelerando o crescimento das empresas com estretégias criativas de marketing digital.",
    text: "Seguimos toda uma Metodologia comprovada para evoluir a sua empresa e seu produto, tudo com acompanhamento de quem conhece e á todo momento, realizando:",
    list: ["Diagnóstico", "Definição do Cliente Ideal", "Estratégia de Conteúdo", "Análise de resultados"]
  },
};

export const worksTwo = {
  "APP": {
    subTitle: "A DWM te acompanha do começo ao fim do projeto, desde a idealização até a manutenção pós-finalização.",
    text: "E com um time de desenvolvedores extremamente especializados, entregamos seu aplicativo seguindo um rigoroso padrão de qualidade, como:",
    list: ["Código limpo e escalável", "Documentação", "Homologação", "Navegação fluída"],
    margin: '200'
  },
  "WEB": {
    subTitle: "A DWM te acompanha do começo ao fim do projeto, desde a idealização até a manutenção pós-finalização. ",
    text: "E com um time de desenvolvedores extremamente especializados, entregamos seu website seguindo um rigoroso padrão de qualidade, como:",
    list: ["Código limpo e escalável", "Documentação", "Homologação", "Navegação fluída"],
    margin: '220'
  },
  "DESIGN UI/UX": {
    subTitle: "A DWM leva seu projeto real para o digital, com qualidade e rapidez.",
    text: "Seu projeto como você gostaria e até melhor, devido á técnicas e padrões utilizados e priorizados nos projetos, como:",
    list: ["Arquitetura da informação", "Usabilidade heurística", "Benchmark do seu negócio", "Fluxo do usuário"],
    margin: '60'
  },
  "SISTEMAS": {
    subTitle: "A DWM leva seu projeto real para o digital, com qualidade e rapidez.",
    text: "Seu projeto como você gostaria e até melhor, devido á técnicas e padrões utilizados e priorizados nos projetos, como:",
    list: ["Código limpo e escalável", "Documentação", "Homologação", "Navegação fluída"],
    margin: '190'
  },
  "MARKETING DIGITAL": {
    subTitle: "A DWM te ajuda á criar um relacionamento com o cliente e á desenvolver uma identidade de marca.",
    text: "O marketing é uma atividade que empreendedores e grandes empresas executam de forma online com o objetivo de atrair novos clientes e negócios,",
    list: ["Estratégia", "Operação", "Processo de Prospecção", "Processo de vendas"],
    margin: '110'
  },
};