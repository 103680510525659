import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import LogoSVG from "../../assets/images/logo.png";
import ButtonContato from "../ButtonContato";
import ButtonNavBar from "../ButtonNavBar";

import Menu from "../Menu";
import "./styles.css";

function NavBar(props) {
  const [viewMobile, setViewMobile] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [classAnimate, setClassAnimate] = useState("animate__fadeInDown");

  const handleClick = () => {
    props.onClick();
  };

  const handleOpenMenu = () => {
    return setOpenMenu(!openMenu);
  };

  useEffect(() => {
    console.log(window.outerWidth);
    if (window.outerWidth <= 600) {
      setViewMobile(true);
    }
  }, []);

  useEffect(() => {
    if (openMenu) {
      setClassAnimate("animate__fadeInDown");
    } else {
      setClassAnimate("animate__fadeOutUp");
    }
  }, [openMenu]);

  return (
    <div className={props.className}>
      {!viewMobile ? (
        <>
          <img className="logoDWM" src={LogoSVG} alt="logo DWM"></img>
          <div className="div-actions">
            <ButtonNavBar href="#empresa">EMPRESA</ButtonNavBar>
            <ButtonNavBar href="#servicos">SERVIÇOS</ButtonNavBar>
            <ButtonNavBar href="#funcionamento">FUNCIONAMENTO</ButtonNavBar>
            <ButtonNavBar href="#portifolio">PORTIFÓLIO</ButtonNavBar>
            <ButtonContato onClick={handleClick} icon={false}>
              CONTATO
            </ButtonContato>
          </div>
        </>
      ) : (
        <>
          <img className="logoDWM" src={LogoSVG} alt="logo DWM"></img>
          <div className="div-actions">
            <ButtonContato onClick={handleClick} icon={false}>
              CONTATO
            </ButtonContato>
            <FontAwesomeIcon
              className="icon-menu"
              data-deg={openMenu && "90"}
              icon={faBars}
              color={"#fff"}
              onClick={handleOpenMenu}
            />
          </div>
          <Menu classAnimate={classAnimate}>
            <ButtonNavBar>EMPRESA</ButtonNavBar>
            <ButtonNavBar>SERVIÇOS</ButtonNavBar>
            <ButtonNavBar>FUNCIONAMENTO</ButtonNavBar>
            <ButtonNavBar>PORTIFÓLIO</ButtonNavBar>
          </Menu>
        </>
      )}
    </div>
  );
}

export default NavBar;
