import React, { useState } from 'react';

import "./styles.css";

function CardTypeWork(props){

  const [selected, setSelected] = useState('');

  const handleClick = () => {
    if(selected === "select"){
      setSelected("");
      props.onClick('remove', props.title);
    }else {
      setSelected("select");
      props.onClick('add', props.title);
    }
  }
  
  return (
    <div className="content-cardTypeWork" data-theme={selected} onClick={handleClick} >
      <p className="title-cardTypeWork" data-theme={selected}>{props.title}</p>
      <p className="text-cardTypeWork" data-theme={selected}>{props.text}</p>
    </div>
  )
}

export default CardTypeWork;