import React from 'react'

import "./styles.css";

function ButtonTypeWork(props){

  const handleOnClick = () => {
    props.onClick(props.name);
  };
  
  return (
    <button 
      className="button-work" 
      data-bg={props.dataBg} 
      onClick={handleOnClick}
    >
      <p className="name-button">{props.name}</p>
    </button>
  )
};

export default ButtonTypeWork;