import React, { useEffect, useState } from "react";

import "./styles.css";

function Steps(props) {
  const [steps, setSteps] = useState([]);

  const handleQtdSteps = () => {
    const newSteps = [];
    for (let i = 0; i <= props.qtdSteps - 1; i++) {
      newSteps.push(i);
    }
    setSteps(newSteps);
  };

  const handleClick = (step) => {
    if (step === props.stepSelect) {
      return;
    }
    props.onClick(step);
  };

  const handleClickUp = () => {
    if (props.stepSelect - 1 < 0) {
      return;
    }
    props.onClick(props.stepSelect - 1);
  };
  const handleClickDown = () => {
    if (props.stepSelect + 1 >= props.qtdSteps) {
      return;
    }
    props.onClick(props.stepSelect + 1);
  };

  useEffect(() => {
    handleQtdSteps();
  }, []);

  return (
    <div className="content-steps">
      <div className="arrow-up" onClick={handleClickUp} />
      {steps.map((step) => {
        return (
          <div
            key={step}
            className="step-circle"
            data-color={props.stepSelect === step ? "select" : ""}
            onClick={() => handleClick(step)}
          />
        );
      })}
      <div className="arrow-down" onClick={handleClickDown} />
    </div>
  );
}

export default Steps;
