import React from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import "./styles.css";

function AlertPage(props){
  return(
    <div className="content-alert" data-display={props.display} data-color={props.color}> 
      <IoMdCheckmarkCircleOutline size="25px" />
      <p> {props.children}</p>
    </div>
  )
}

export default AlertPage;