import React from "react";
import CheckIconSvg from "../../assets/images/check_circle.svg";

import "./styles.css";
function ListWorks(props) {
  return (
    <ul className="list-work">
      {props.topics.map((topic, index) => {
        return (
          <div key={index} className="item-list">
            <img
              className="icon-check"
              src={CheckIconSvg}
              alt="icone de checado"
            />
            <p>{topic}</p>
          </div>
        );
      })}
    </ul>
  );
}

export default ListWorks;
