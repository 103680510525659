import React, { useEffect, useState } from "react";
import "./App.css";
import ButtonScrollSVG from "./assets/images/buttonScroll.svg";
import ButtonWhatsSVG from "./assets/images/buttonWpp.svg";
import caminhoSVG from "./assets/images/caminhoCirculos.svg";
import DesignSVG from "./assets/images/designUI.svg";
import OqueDWMSVG from "./assets/images/imagemOqueeDWM.svg";
import MarketingSVG from "./assets/images/marketing.svg";
import Phone from "./assets/images/phone.svg";
import SistemasSVG from "./assets/images/sistemasImg.svg";
import WebSVG from "./assets/images/webImage.svg";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import { ArrayImages } from "./utils/portifolio";

import IlustracaoWebsiteSVG from "./assets/images/IlustraçãoWebsite.svg";
import DesenvolveAppSVG from "./assets/images/desenvolveApp.svg";
import ilustracaoDesignSVG from "./assets/images/ilustracaoDesign.svg";
import ilustracaoMarketingSVG from "./assets/images/ilustracaoMarketing.svg";
import ilustracaoSistemasSVG from "./assets/images/ilustracaoSistemas.svg";

import DevAppSVG from "./assets/images/devAppImage.svg";
import DevDesgnSVG from "./assets/images/devDesignImage.svg";
import MarktingSVG from "./assets/images/devMarketingImage.svg";
import SistemSVG from "./assets/images/devSistemasImage.svg";
import WebImageSVG from "./assets/images/devWebImage.svg";

import ChatSVG from "./assets/images/chatsStep.svg";
import Circle1x from "./assets/images/ellipse1x.svg";
import EnvelopeSVG from "./assets/images/envelope.svg";
import MaosSVG from "./assets/images/maosStep.svg";

import IlustraçãoDesenvolvimento from "./assets/images/Ilustração desenvolvimento.svg";
import IlustraçãoManutenção from "./assets/images/Ilustração manutenção.svg";
import IlustraçãoPlanejamento from "./assets/images/Ilustração planejamento.svg";
import IlustraçãoProjetoPronto from "./assets/images/Ilustração projeto pronto.svg";

import ArrowMetodologiaMobile from "./assets/images/arrow.svg";

import "./assets/global/styles.css";
import ButtonTypeWork from "./components/ButtonTypeWork";
import CardRow from "./components/CardRow";
import CardStepOrcamento from "./components/CardStepOrcamento";
import ListWorks from "./components/ListWorks";
import TitlePage from "./components/TitlePage";

import ButtonContato from "./components/ButtonContato";
import CardMetodologia from "./components/CardMetodologia";
import CardMetodologiaTwo from "./components/CardMetodologiaTwo";
import DialogContato from "./components/DialogContato";
import DivImagensPortifolio from "./components/DivImagensPortifolio";
import DivTecnologia from "./components/DivTecnologia";
import Steps from "./components/Steps";
import { worksOne, worksTwo } from "./utils/works";

import { DiMongodb } from "react-icons/di";
import {
  FaFacebookF,
  FaFigma,
  FaJava,
  FaLinkedinIn,
  FaNodeJs,
  FaPython,
  FaReact,
  FaVuejs,
} from "react-icons/fa";
import { SiAdobexd, SiGoogleanalytics, SiMysql } from "react-icons/si";

function App() {
  const [showNav, setShowNav] = useState(false);
  const [openSendContato, setOpenSendContato] = useState(false);
  const [typeWork, setTypeWork] = useState("APP");
  const [ilustration, setIlustration] = useState(DesenvolveAppSVG);
  const [ilustrationTwo, setIlustrationTwo] = useState(DevAppSVG);
  const [stepSelected, setStepSelected] = useState(0);
  const [stepTecnologia, setStepTecnologia] = useState(0);
  const [sizeIcon, setSizeIcon] = useState("100px");

  const icons = [
    [
      <FaNodeJs
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <FaJava
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <FaPython
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
    ],
    [
      <FaReact
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <FaVuejs
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <FaFigma
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
    ],
    [
      <FaLinkedinIn
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <FaFacebookF
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <DiMongodb
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
    ],
    [
      <SiMysql
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <SiAdobexd
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
      <SiGoogleanalytics
        className="animate__animated animate__slideInUp"
        size={sizeIcon}
      />,
    ],
  ];

  window.addEventListener("scroll", () => {
    document.querySelectorAll(".oque-e-dwm").forEach((element) => {
      if (element.getBoundingClientRect().top < 50) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    });
  });

  const typeIlustration = {
    APP: DesenvolveAppSVG,
    WEB: IlustracaoWebsiteSVG,
    "DESIGN UI/UX": ilustracaoDesignSVG,
    SISTEMAS: ilustracaoSistemasSVG,
    "MARKETING DIGITAL": ilustracaoMarketingSVG,
  };

  const typeIlustrationTwo = {
    APP: DevAppSVG,
    WEB: WebImageSVG,
    "DESIGN UI/UX": DevDesgnSVG,
    SISTEMAS: SistemSVG,
    "MARKETING DIGITAL": MarktingSVG,
  };

  const handleOptionWork = (type) => {
    setIlustrationTwo(typeIlustrationTwo[type]);
    setIlustration(typeIlustration[type]);
    return setTypeWork(type);
  };

  const handleOpenSendContato = () => {
    setOpenSendContato(!openSendContato);
  };

  const handleCloseSendContato = () => {
    setOpenSendContato(false);
  };

  const handleSelectStep = (stepNumber) => {
    setStepSelected(stepNumber);
  };
  const handleStepTecnologia = (stepNumber) => {
    setStepTecnologia(stepNumber);
  };

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setSizeIcon("60px");
    }
  }, []);

  return (
    <div className="App">
      <DialogContato open={openSendContato} onClose={handleCloseSendContato} />
      <NavBar className="content-navbar" onClick={handleOpenSendContato} />
      {/* <main> */}
      {showNav && (
        <NavBar
          className="content-navbar-black animate__animated animate__fadeInDown"
          onClick={handleOpenSendContato}
        />
      )}
      <main style={{ filter: openSendContato ? "blur(20px)" : "" }}>
        <div className="home">
          <div className="home-gradiente"></div>
          <div style={{ color: "#fff", zIndex: "1000" }}>
            <h1>A presença digital que sua empresa necessita, </h1>
            <h1 class="typist" data-typist-sequence="intro">
              <span>seja criando um Website.</span>
              <span>com um Sistema.</span>
              <span>seja com um Aplicativo.</span>
              <span>seja criando um Design UI/UX.</span>
              <span>através do Marketing Digital.</span>
            </h1>
          </div>
        </div>
        <div className="oque-e-dwm" id="empresa">
          <TitlePage
            dataMargin="104"
            title="O QUE É A"
            titleBold="DWM?"
            lazyLoad={true}
          ></TitlePage>
          <div className="div-oque-dwm">
            <img
              className="img-oque-e-DWM"
              src={OqueDWMSVG}
              alt="imagem oque e dwm"
            ></img>
            <div className="div-paragrafo-oque-dwm">
              <p className="paragrafo-oque-dwm">
                A <strong>DWM</strong> é uma empresa de desenvolvimento digital
                com foco em trazer{" "}
                <strong>seu projeto para o mundo digital</strong> e oferecer
                soluções completas para sua presença online. Desde 2021, nós
                temos trazido resultados para nossos clientes e acumulado uma
                ampla experiência no mercado.
              </p>
              <p className="paragrafo-oque-dwm">
                Com a DWM, você pode contar desde o início com um{" "}
                <strong>atendimento ágil e personalizado</strong>. Aqui, você
                irá se comunicar com profissionais especializados em
                desenvolvimento, design, marketing digital e muito mais. Além
                disso, oferecemos um gerente de projetos dedicado para
                auxiliá-lo em todas as etapas do processo de idealização...
                Confira como podemos ajudar você!
              </p>
            </div>
          </div>
        </div>
        <div className="servicos" id="servicos">
          <TitlePage
            lazyLoad={true}
            dataMargin="54"
            title="SERVIÇOS"
            paragrafo="Tudo que precisa em seu negócio em um só lugar"
          ></TitlePage>
          <div className="row-div-cards">
            <div className="div-cards">
              <a href="#info-projetos">
                <CardRow
                  className="content-card-row"
                  lorIcon="https://cdn.lordicon.com/jqeuwnmb.json"
                  onClick={handleOptionWork}
                  image={Phone}
                  title="APP"
                >
                  Realizamos todo tipo de serviço para o seu aplicativo, seja
                  mobile ou desktop.
                </CardRow>
              </a>
              <a href="#info-projetos">
                <CardRow
                  className="content-card-row"
                  lorIcon="https://cdn.lordicon.com/rwotyanb.json"
                  onClick={handleOptionWork}
                  image={DesignSVG}
                  title="DESIGN UI/UX"
                >
                  O projeto que desejar, moderno e intuitivo, tudo para a melhor
                  experiência do usuário.
                </CardRow>
              </a>
            </div>
            <div className="div-cards">
              <a href="#info-projetos">
                <CardRow
                  className="content-card-column"
                  lorIcon="https://cdn.lordicon.com/uqpazftn.json"
                  onClick={handleOptionWork}
                  image={MarketingSVG}
                  title="MARKETING DIGITAL"
                >
                  Estratégias para agregar valor e chamar a atenção do cliente,
                  gerando lucros.
                </CardRow>
              </a>
            </div>
            <div className="div-cards">
              <a href="#info-projetos">
                <CardRow
                  className="content-card-row"
                  lorIcon="https://cdn.lordicon.com/qhgmphtg.json"
                  onClick={handleOptionWork}
                  image={WebSVG}
                  title="WEB"
                >
                  Seu negócio com site totalmente responsivo de acordo com a sua
                  necessidade.
                </CardRow>
              </a>
              <a href="#info-projetos">
                <CardRow
                  className="content-card-row"
                  lorIcon="https://cdn.lordicon.com/xxtkutpo.json"
                  onClick={handleOptionWork}
                  image={SistemasSVG}
                  title="SISTEMAS"
                >
                  Criamos seu sistema, para qualquer tipo de aplicação ou
                  necessidade.
                </CardRow>
              </a>
            </div>
          </div>
        </div>
        <div className="info-projetos" id="info-projetos">
          <div className="div-actions-works">
            <ButtonTypeWork
              onClick={handleOptionWork}
              dataBg={typeWork === "APP" ? "select" : ""}
              name="APP"
            />
            <ButtonTypeWork
              onClick={handleOptionWork}
              dataBg={typeWork === "WEB" ? "select" : ""}
              name="WEB"
            />
            <ButtonTypeWork
              onClick={handleOptionWork}
              dataBg={typeWork === "DESIGN UI/UX" ? "select" : ""}
              name="DESIGN UI/UX"
            />
            <ButtonTypeWork
              onClick={handleOptionWork}
              dataBg={typeWork === "SISTEMAS" ? "select" : ""}
              name="SISTEMAS"
            />
            <ButtonTypeWork
              onClick={handleOptionWork}
              dataBg={typeWork === "MARKETING DIGITAL" ? "select" : ""}
              name="MARKETING DIGITAL"
            />
          </div>
          <div className="content-info-projetos">
            <h3 id="title-info-projetos">{worksOne[typeWork].title}</h3>
            <div className="detalhes-work-um">
              <img
                className="img-detalhes-work"
                src={ilustration}
                alt="detalhes de trabalho"
              />
              <div>
                <h4 className="detalhes-work-title">
                  {worksOne[typeWork].subTitle}
                </h4>
                <p className="detalhes-work-p">{worksOne[typeWork].text}</p>
                <ListWorks topics={worksOne[typeWork].list} />
              </div>
            </div>
          </div>

          <CardMetodologia
            subTitle={worksTwo[typeWork].subTitle}
            image={ilustrationTwo}
            dataMargin={worksTwo[typeWork].margin}
            list={worksTwo[typeWork].list}
          >
            {worksTwo[typeWork].text}
          </CardMetodologia>
        </div>
        <div className="div-contratacao" id="funcionamento">
          <TitlePage
            lazyLoad={true}
            dataMargin="42"
            title="CONTRATAÇÃO"
            paragrafo="Funcionamento da orçagem até o desenvolvimento"
          ></TitlePage>
          <div className="div-steps">
            <CardStepOrcamento
              image={EnvelopeSVG}
              lorIcon="https://cdn.lordicon.com/rhvddzym.json"
              title="PEÇA UM ORÇAMENTO"
            >
              Informe do que precisa e qual a sua necessidade, com alguns
              detalhes iniciais. Tudo isso para termos uma noção do que é
              projeto.
            </CardStepOrcamento>
            <img className="image-circle" src={Circle1x} alt="divisor um" />
            <CardStepOrcamento
              image={ChatSVG}
              lorIcon="https://cdn.lordicon.com/zpxybbhl.json"
              title="ENTRAREMOS EM CONTATO"
            >
              Para entendermos tudo o que precisa ser feito no projeto e assim
              instruirmos como seria desenvolvido.
            </CardStepOrcamento>
            <img className="image-circle" src={Circle1x} alt="divisor dois" />
            <CardStepOrcamento
              image={MaosSVG}
              lorIcon="https://cdn.lordicon.com/cjieiyzp.json"
              title="VALORES E PRAZOS"
            >
              Após o contato inicial, esclarecendo tudo que será requisitado,
              virá a parte das negociações para decidir a contratação.
            </CardStepOrcamento>
          </div>
        </div>
        <div className="div-metodologia">
          <TitlePage
            lazyLoad={true}
            dataMargin="54"
            title="METODOLOGIA"
            paragrafo="Resumo de como seu projeto é desenvolvido"
          ></TitlePage>
          <div className="columns-metodologia">
            <div classname="column-metodologia">
              <CardMetodologiaTwo
                marginTop="140"
                image={IlustraçãoPlanejamento}
                title="PLANEJAMENTO"
                text="Com diversas conferências, alinhamos o que sua empresa precisa e traçamos um caminho para desenvolver o projeto."
              />
              <div className="image-arrow-metodologia">
                <img src={ArrowMetodologiaMobile} alt="guia dos passos" />
              </div>
              <CardMetodologiaTwo
                marginTop="140"
                image={IlustraçãoProjetoPronto}
                title="ENTREGA DO PROJETO"
                text="É liberado a versão finalizada (testada e funcional), e são liberadas outras ao longo do tempo, mantendo seu sistema em constante evolução."
              />
            </div>
            <div className="image-arrow-metodologia">
              <img src={ArrowMetodologiaMobile} alt="guia dos passos" />
            </div>
            <div className="image-metodologia">
              <img
                style={{ marginTop: "323px" }}
                src={caminhoSVG}
                alt="ilustração do caminho"
              />
            </div>
            <div>
              <CardMetodologiaTwo
                marginTop="280"
                image={IlustraçãoDesenvolvimento}
                title="DESENVOLVIMENTO"
                text="Colocamos o planejamento em prática, criando o seu projeto com base no que foi acertado, aperfeiçoando o modelo inicial e compartilhando o processo com você."
              />
              <div className="image-arrow-metodologia">
                <img src={ArrowMetodologiaMobile} alt="guia dos passos" />
              </div>
              <CardMetodologiaTwo
                marginTop="280"
                image={IlustraçãoManutenção}
                title="PROSSEGUIMENTO"
                text="A metologia termina com a necessidade de manutenção, ajustando ou acrescentando o que acha preciso, um serviço essencial que a DWM presta."
              />
            </div>
          </div>
          <ButtonContato onClick={handleOpenSendContato}>
            SOLICITAR ORÇAMENTO
          </ButtonContato>
        </div>
        <div
          className="div-portifolio"
          id="portifolio"
          data-bg={ArrayImages[stepSelected].color}
        >
          <TitlePage
            lazyLoad={true}
            dataMargin="54"
            title="PORTIFÓLIO"
            paragrafo="Projetos realizados para outros clientes"
          />
          <div className="content-portifolio">
            <DivImagensPortifolio
              desk={ArrayImages[stepSelected].desk}
              mobile={ArrayImages[stepSelected].mobile}
            />
            <Steps
              qtdSteps={ArrayImages.length}
              stepSelect={stepSelected}
              onClick={handleSelectStep}
            />
          </div>
        </div>
        <div className="div-tecnologias">
          <TitlePage
            lazyLoad={true}
            dataMargin="54"
            title="TECNOLOGIAS"
            paragrafo="Utilizadas para criação dos projetos"
          />
          <DivTecnologia
            tecnologias={icons[stepTecnologia]}
            arrayIcon={icons}
            step={stepTecnologia}
            onClick={handleStepTecnologia}
          />
        </div>
        <a
          target="_blank"
          rel="no noreferrer"
          href="https://api.whatsapp.com/send?phone=5511977189773&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es"
        >
          <button className="button-wpp">
            <img src={ButtonWhatsSVG} alt="wpp" />
          </button>
        </a>
        <button
          className="scroll"
          data-up={showNav ? "up" : "down"}
          onClick={() =>
            showNav ? window.scroll(0, 0) : window.scroll(0, window.innerHeight)
          }
        >
          <img src={ButtonScrollSVG} alt="button home" />
        </button>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
