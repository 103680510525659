import React from "react";

import "./styles.css";

function CardMetodologiaTwo(props) {
  return (
    <div className="content-card-metodologia" data-margin={props.marginTop}>
      <img src={props.image} alt="metodologia dois" />
      <h5>{props.title}</h5>
      <p>{props.text}</p>
    </div>
  );
}

export default CardMetodologiaTwo;
