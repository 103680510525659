import React from 'react';

import './styles.css';

function CardRow(props){

  const handleOnClick = () => {
    props.onClick(props.title);
  };

  return(
    
    <div className={props.className} onClick={handleOnClick}>
      <div className={`${props.className}-div-image`}>
        {/* <img src={props.image} /> */}
        <lord-icon
          src={props.lorIcon}
          trigger="loop-on-hover"
          colors="primary:#FFFFFF,secondary:#FF7400"
          stroke="46"
          style={{width:"130px",height:"100%"}}>
        </lord-icon>
      </div>
      <div className="div-text">
        <p className="p-bold">{props.title}</p>
        <p className="p-regular">{props.children}</p>
      </div>
    </div>
  )
}

export default CardRow;