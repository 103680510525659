import React from "react";
import LogoSVG from "../../assets/images/logo.png";
import "./styles.css";

function Footer() {
  return (
    <div className="content-footer">
      <img className="logo-footer" src={LogoSVG} alt="logo DWM"></img>
      <div className="row-dados">
        <div className="contatos">
          <div className="contato">
            <p className="p-red">Telefone</p>
            <p className="p-light">(11) 97718-9773</p>
          </div>
          <div className="contato">
            <p className="p-red">E-mail</p>
            <p className="p-light">comercial@dwmtecnologia.com.br</p>
          </div>
        </div>
        <div className="direitos">
          <p className="p-light">
            Todos os direitos reservados á DWM Tecnologia Ltda © 2021
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
